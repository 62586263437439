import { h, JSX } from 'preact';
import { CaptionSettings } from './types.ts';
import { CaptionsSettingsMenu } from './CaptionsSettingsMenu/CaptionsSettingsMenu.tsx';
import { CaptionsSettingsIcon } from './CaptionsSettingsIcon.tsx';
import { BaseControlProps } from '../../../../../../../types/controls.ts';
import { Menu, MenuRoot, MenuTrigger } from '../../../../../../shared/components/Menu/index.ts';
import VisuallyHiddenElem from '../../../shared/ui_components/VisuallyHiddenElem.jsx';
import { TranscriptItem } from './TranscriptItem.jsx';
import { CaptionsItem } from './CaptionsItem.jsx';
import {
  DEFAULT_BUTTON_HEIGHT_PX,
  DEFAULT_MENU_ITEM_HEIGHT_PX,
} from '../../../shared/ui_components/UiConstants.ts';

type CaptionsMenuItem = {
  isSelected: boolean;
  onClick: () => void;
  text: string;
};

export type CaptionsMenuProps = BaseControlProps & {
  isPlaybarEnabled: boolean;
  isTranscriptEnabled: boolean;
  items: CaptionsMenuItem[];
  onCaptionsSettingsUpdated: (
    captionsSettings: CaptionSettings,
    changedCaptionsSettings: Partial<CaptionSettings>,
  ) => void;
  shouldShowCaptionsSettings: boolean;
  toggleTranscript: () => void;
};

export const CaptionsMenu = ({
  isTranscriptEnabled,
  isPlaybarEnabled,
  items,
  controlBarBorderRadius,
  scale,
  playerLanguage,
  toggleTranscript,
  shouldShowCaptionsSettings,
  onCaptionsSettingsUpdated,
}: CaptionsMenuProps): JSX.Element => {
  return (
    <MenuRoot scale={scale} controlBarBorderRadius={controlBarBorderRadius}>
      <div class="w-captions-menu w-css-reset w-css-reset-tree">
        <Menu>
          {isTranscriptEnabled && isPlaybarEnabled && (
            <TranscriptItem
              playerLanguage={playerLanguage}
              toggleTranscript={toggleTranscript}
              controlBarBorderRadius={controlBarBorderRadius}
              scale={scale}
            />
          )}

          <fieldset style={{ border: 0, padding: 0, margin: 0 }}>
            <VisuallyHiddenElem tagName="legend">Captions Menu</VisuallyHiddenElem>

            {items.map((item, index) => (
              <CaptionsItem
                controlBarBorderRadius={controlBarBorderRadius}
                scale={scale}
                item={item}
                index={index}
                isLastItem={index === items.length - 1 && !shouldShowCaptionsSettings}
              />
            ))}
          </fieldset>

          {shouldShowCaptionsSettings && (
            <MenuTrigger menuKey="settings" shouldHaveRoundedBottomCorners={true}>
              <div
                style={{
                  height: `${DEFAULT_MENU_ITEM_HEIGHT_PX * scale}px`,
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: `${DEFAULT_BUTTON_HEIGHT_PX * scale}px`,
                }}
              >
                <CaptionsSettingsIcon scale={scale} />
                Captions settings
              </div>
            </MenuTrigger>
          )}
        </Menu>

        <CaptionsSettingsMenu onCaptionsSettingsUpdated={onCaptionsSettingsUpdated} />
      </div>
    </MenuRoot>
  );
};
