import { useState } from 'preact/hooks';
import { CaptionSettings } from '../../types.ts';
import { getCaptionSettings, updateCaptionViewerPreferences } from '../../localStorageHelpers.ts';
import { CaptionsMenuProps } from '../../CaptionsMenu.tsx';

type UseCaptionSettingsFromLocalStorageResult = {
  captionsSettings: CaptionSettings;
  setCaptionsSettings: (captionSettings: Partial<CaptionSettings>) => void;
};

type UseCaptionsSettingsFromLocalStorageProps = Partial<
  Pick<CaptionsMenuProps, 'onCaptionsSettingsUpdated'>
>;

export const useCaptionsSettingsFromLocalStorage = ({
  onCaptionsSettingsUpdated,
}: UseCaptionsSettingsFromLocalStorageProps): UseCaptionSettingsFromLocalStorageResult => {
  const [captionsSettings, setCaptionsSettings] = useState(getCaptionSettings());

  const setCaptionsSettingsAndLocalStorage = (
    changedCaptionsSettings: Partial<CaptionSettings>,
  ): void => {
    const newCaptionSettings = { ...captionsSettings, ...changedCaptionsSettings };
    setCaptionsSettings(newCaptionSettings);
    updateCaptionViewerPreferences(newCaptionSettings);
    onCaptionsSettingsUpdated?.(newCaptionSettings, changedCaptionsSettings);
  };

  return {
    captionsSettings,
    setCaptionsSettings: setCaptionsSettingsAndLocalStorage,
  };
};
