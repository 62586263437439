import type { JSX } from 'preact';
import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { MenuItemButton, MenuItemButtonProps } from './MenuItemButton.tsx';
import { useMenuRootContext } from '../hooks/useMenuRootContext.tsx';

type SubMenuTriggerProps = Omit<MenuItemButtonProps, 'onClick'> & {
  menuKey: string;
};

export const MenuTrigger = (props: SubMenuTriggerProps): JSX.Element => {
  const { openMenu, navigationDirection, prevMenuKey } = useMenuRootContext();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onClickTrigger = () => {
    openMenu(props.menuKey);
  };

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) {
      return;
    }
    if (navigationDirection !== 'towards-root') {
      return;
    }
    if (prevMenuKey !== props.menuKey) {
      return;
    }

    button.focus({ preventScroll: true });
  }, [navigationDirection, prevMenuKey, props.menuKey]);

  return <MenuItemButton ref={buttonRef} {...props} onClick={onClickTrigger} />;
};
