import { Fragment, h, type JSX } from 'preact';
import type { PropsWithChildren } from 'preact/compat';
import { useCaptionsSettingsFromLocalStorage } from './hooks/useCaptionSettingsFromLocalStorage.ts';
import { CharacterEdgeStyleRadioGroup } from './components/CharacterEdgeStyleRadioGroup.tsx';
import { MenuForwardIcon } from '../../../../../../../shared/components/Menu/components/MenuForwardIcon.tsx';
import { CaptionsSettingsIcon } from '../CaptionsSettingsIcon.tsx';
import { CaptionsSettingsMenuSection } from './CaptionsSettingsMenuSection.tsx';
import { MenuItemButton } from '../../../../../../../shared/components/Menu/components/MenuItemButton.tsx';
import { Menu, MenuTrigger } from '../../../../../../../shared/components/Menu/index.ts';
import { useMenuRootContext } from '../../../../../../../shared/components/Menu/hooks/useMenuRootContext.tsx';
import {
  RadioColorSwatch,
  RadioGroup,
  RadioPill,
} from '../../../../../../../shared/components/RadioGroup/index.ts';
import { FontFamilyRadioGroup } from './components/FontFamilyRadioGroup.tsx';
import { isCaptionColor, isCaptionFontSize, isCaptionOpacityPercentage } from '../types.ts';
import { getFontStyleForCaptions } from '../utilities/getFontStyleForCaptions.ts';
import {
  BASE_COLORS,
  DEFAULT_CAPTION_SETTINGS,
  FONT_SIZES,
  NON_ZERO_OPACITY_PERCENTAGES,
  OPACITY_PERCENTAGES,
  SETTINGS_MENU_PADDING_X_PX,
} from '../consts.ts';
import { CaptionsMenuProps } from '../CaptionsMenu.tsx';

const SUBMENU_TRIGGER_FONT_SIZE_PX = 12;

type CaptionsSettingsMenuTriggerProps = PropsWithChildren<{
  menuKey: string;
  scale: number;
}>;

const CaptionsSettingsMenuTrigger = ({
  menuKey,
  scale,
  children,
}: CaptionsSettingsMenuTriggerProps) => {
  return (
    <MenuTrigger menuKey={menuKey}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          fontSize: `${SUBMENU_TRIGGER_FONT_SIZE_PX * scale}px`,
          position: 'relative',
          paddingLeft: `${SETTINGS_MENU_PADDING_X_PX * scale}px`,
        }}
      >
        {children}
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <MenuForwardIcon scale={scale} />
        </div>
      </div>
    </MenuTrigger>
  );
};

// This is just for sections that need to have some padding. Not all do, like
// those with triggers in them, for which the hover effect should take the full
// menu width.
const MenuSectionContentWrapper = ({ children }: PropsWithChildren) => {
  const {
    uiContext: { scale },
  } = useMenuRootContext();

  return <div style={{ padding: `0 ${SETTINGS_MENU_PADDING_X_PX * scale}px` }}>{children}</div>;
};

type CaptionSettingsMenuProps = Pick<CaptionsMenuProps, 'onCaptionsSettingsUpdated'>;

export const CaptionsSettingsMenu = ({
  onCaptionsSettingsUpdated,
}: CaptionSettingsMenuProps): JSX.Element => {
  const {
    uiContext: { scale },
  } = useMenuRootContext();

  const { captionsSettings, setCaptionsSettings } = useCaptionsSettingsFromLocalStorage({
    onCaptionsSettingsUpdated,
  });

  return (
    <Fragment>
      <Menu menuKey="settings" label="Captions settings">
        <CaptionsSettingsMenuSection title="Font size">
          <MenuSectionContentWrapper>
            <RadioGroup ariaLabel="Font size" scale={scale}>
              {FONT_SIZES.map((percentage) => (
                <RadioPill
                  key={percentage}
                  name="font-size"
                  label={`${percentage}%`}
                  value={percentage.toString()}
                  scale={scale}
                  checked={captionsSettings.fontSize === percentage}
                  onChange={(event) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      return;
                    }

                    const fontSize = Number(event.target.value);

                    if (!isCaptionFontSize(fontSize)) {
                      return;
                    }

                    setCaptionsSettings({
                      fontSize,
                    });
                  }}
                />
              ))}
            </RadioGroup>
          </MenuSectionContentWrapper>
        </CaptionsSettingsMenuSection>

        <CaptionsSettingsMenuSection title="Font family" style={{ gap: '4px' }}>
          <CaptionsSettingsMenuTrigger menuKey="font-family" scale={scale}>
            <span style={getFontStyleForCaptions(captionsSettings.fontFamily)}>
              {captionsSettings.fontFamily}
            </span>
          </CaptionsSettingsMenuTrigger>
        </CaptionsSettingsMenuSection>

        <CaptionsSettingsMenuSection title="Font color">
          <MenuSectionContentWrapper>
            <RadioGroup ariaLabel="Font color" scale={scale}>
              {BASE_COLORS.map((color) => (
                <RadioColorSwatch
                  key={color}
                  scale={scale}
                  name="Font color"
                  ariaLabel={color}
                  value={color}
                  checked={captionsSettings.fontColor === color}
                  onChange={(event) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      return;
                    }

                    const fontColor = event.target.value;

                    if (!isCaptionColor(fontColor)) {
                      return;
                    }

                    setCaptionsSettings({
                      fontColor,
                    });
                  }}
                />
              ))}
            </RadioGroup>
          </MenuSectionContentWrapper>
        </CaptionsSettingsMenuSection>

        <CaptionsSettingsMenuSection title="Font opacity">
          <MenuSectionContentWrapper>
            <RadioGroup ariaLabel="Font opacity" scale={scale}>
              {NON_ZERO_OPACITY_PERCENTAGES.map((percentage) => (
                <RadioPill
                  key={percentage}
                  name="font-opacity"
                  label={`${percentage}%`}
                  value={percentage.toString()}
                  checked={captionsSettings.fontOpacityPercentage === percentage}
                  scale={scale}
                  onChange={(event) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      return;
                    }

                    const fontOpacityPercentage = Number(event.target.value);

                    if (!isCaptionOpacityPercentage(fontOpacityPercentage)) {
                      return;
                    }

                    setCaptionsSettings({
                      fontOpacityPercentage,
                    });
                  }}
                />
              ))}
            </RadioGroup>
          </MenuSectionContentWrapper>
        </CaptionsSettingsMenuSection>

        <CaptionsSettingsMenuSection title="Character edge style" style={{ gap: '4px' }}>
          <CaptionsSettingsMenuTrigger menuKey="character-edge-styles" scale={scale}>
            {captionsSettings.characterEdgeStyle}
          </CaptionsSettingsMenuTrigger>
        </CaptionsSettingsMenuSection>

        <CaptionsSettingsMenuSection title="Background color">
          <MenuSectionContentWrapper>
            <RadioGroup ariaLabel="Background color" scale={scale}>
              {BASE_COLORS.map((color) => (
                <RadioColorSwatch
                  key={color}
                  scale={scale}
                  name="Background color"
                  ariaLabel={color}
                  value={color}
                  checked={captionsSettings.backgroundColor === color}
                  onChange={(event) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      return;
                    }

                    const backgroundColor = event.target.value;

                    if (!isCaptionColor(backgroundColor)) {
                      return;
                    }

                    setCaptionsSettings({
                      backgroundColor,
                    });
                  }}
                />
              ))}
            </RadioGroup>
          </MenuSectionContentWrapper>
        </CaptionsSettingsMenuSection>

        <CaptionsSettingsMenuSection title="Background opacity">
          <MenuSectionContentWrapper>
            <RadioGroup ariaLabel="Background opacity" scale={scale}>
              {OPACITY_PERCENTAGES.map((percentage) => (
                <RadioPill
                  key={percentage}
                  name="background-opacity"
                  label={`${percentage}%`}
                  value={percentage.toString()}
                  scale={scale}
                  checked={captionsSettings.backgroundOpacityPercentage === percentage}
                  onChange={(event) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      return;
                    }

                    const backgroundOpacityPercentage = Number(event.target.value);

                    if (!isCaptionOpacityPercentage(backgroundOpacityPercentage)) {
                      return;
                    }

                    setCaptionsSettings({
                      backgroundOpacityPercentage,
                    });
                  }}
                />
              ))}
            </RadioGroup>
          </MenuSectionContentWrapper>
        </CaptionsSettingsMenuSection>

        <CaptionsSettingsMenuSection title="Window opacity">
          <MenuSectionContentWrapper>
            <RadioGroup ariaLabel="Window opacity" scale={scale}>
              {OPACITY_PERCENTAGES.map((percentage) => (
                <RadioPill
                  key={percentage}
                  name="window-opacity"
                  label={`${percentage}%`}
                  value={percentage.toString()}
                  scale={scale}
                  checked={captionsSettings.windowOpacityPercentage === percentage}
                  onChange={(event) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      return;
                    }

                    const windowOpacityPercentage = Number(event.target.value);

                    if (!isCaptionOpacityPercentage(windowOpacityPercentage)) {
                      return;
                    }

                    setCaptionsSettings({
                      windowOpacityPercentage,
                    });
                  }}
                />
              ))}
            </RadioGroup>
          </MenuSectionContentWrapper>
        </CaptionsSettingsMenuSection>

        <CaptionsSettingsMenuSection title="Window color">
          <MenuSectionContentWrapper>
            <RadioGroup ariaLabel="Window color" scale={scale}>
              {BASE_COLORS.map((color) => (
                <RadioColorSwatch
                  key={color}
                  scale={scale}
                  name="Window color"
                  ariaLabel={color}
                  value={color}
                  checked={captionsSettings.windowColor === color}
                  onChange={(event) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      return;
                    }

                    const windowColor = event.target.value;

                    if (!isCaptionColor(windowColor)) {
                      return;
                    }

                    setCaptionsSettings({
                      windowColor,
                    });
                  }}
                />
              ))}
            </RadioGroup>
          </MenuSectionContentWrapper>
        </CaptionsSettingsMenuSection>

        <MenuItemButton
          onClick={() => {
            setCaptionsSettings(DEFAULT_CAPTION_SETTINGS);
          }}
          shouldHaveRoundedBottomCorners={true}
        >
          <CaptionsSettingsIcon scale={scale} />
          Reset to defaults
        </MenuItemButton>
      </Menu>

      <Menu menuKey="font-family" label="Font family">
        <FontFamilyRadioGroup
          selectedFontOptionLabel={captionsSettings.fontFamily}
          setSelectedFontOptionLabel={(fontFamily) => {
            setCaptionsSettings({
              fontFamily,
            });
          }}
        />
      </Menu>

      <Menu menuKey="character-edge-styles" label="Character edge style">
        <CharacterEdgeStyleRadioGroup
          selectedEdgeStyleOption={captionsSettings.characterEdgeStyle}
          setSelectedEdgeStyleOption={(characterEdgeStyle) => {
            setCaptionsSettings({
              characterEdgeStyle,
            });
          }}
        />
      </Menu>
    </Fragment>
  );
};
