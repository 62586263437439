const isInputElement = (element: HTMLElement): element is HTMLInputElement => {
  return element.tagName === 'INPUT';
};

const isDisableableElement = (
  element: HTMLElement,
): element is HTMLButtonElement | HTMLInputElement => {
  return isInputElement(element) || element.tagName === 'BUTTON';
};

/**
 * Returns a list of potential tabbable candidates.
 *
 * NOTE: This is only a close approximation. For example it doesn't take into
 * account cases like when elements are not visible. This cannot be worked out
 * easily by just reading a property, but rather necessitate runtime knowledge
 * (computed styles, etc). We deal with these cases separately.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/TreeWalker Credit:
 * https://github.com/discord/focus-layers/blob/master/src/util/wrapFocus.tsx#L1
 * and
 * https://github.com/radix-ui/primitives/blob/e261047abfa41f54a4b26ab1c5ca4975ed3f4e9f/packages/react/focus-scope/src/focus-scope.tsx
 */

const getFocusableElements = (container: HTMLElement) => {
  const nodes: HTMLElement[] = [];
  const walker = document.createTreeWalker(container, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (node: Node) => {
      if (!(node instanceof HTMLElement)) {
        return NodeFilter.FILTER_SKIP;
      }
      const isHiddenInput = isInputElement(node) && node.type === 'hidden';
      if ((isDisableableElement(node) && node.disabled) || node.hidden || isHiddenInput)
        return NodeFilter.FILTER_SKIP;
      // `.tabIndex` is not the same as the `tabindex` attribute. It works on the
      // runtime's understanding of tabbability, so this automatically accounts
      // for any kind of element that could be tabbed to.
      return node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    },
  });
  while (walker.nextNode()) nodes.push(walker.currentNode as HTMLElement);
  // we do not take into account the order of nodes with positive `tabIndex` as it
  // hinders accessibility to have tab order different from visual order.
  return nodes;
};

export const focusFirstFocusableElement = (container: HTMLElement): void => {
  const elements = getFocusableElements(container);
  elements[0]?.focus({ preventScroll: true });
};
