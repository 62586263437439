import { CSSProperties } from 'preact/compat';
import { getFontVariantForFontOptionLabel } from '../../utilities/captions/getFontVariantForFontOptionLabel.ts';
import { FontOptionLabel } from '../types.ts';
import { getFontFamilyForFontOptionLabel } from '../../utilities/captions/getFontFamilyForFontOptionLabel.ts';

export const getFontStyleForCaptions = (
  fontOptionLabel: FontOptionLabel,
): Pick<CSSProperties, 'fontFamily' | 'fontVariant'> => {
  return {
    fontFamily: getFontFamilyForFontOptionLabel(fontOptionLabel),
    fontVariant: getFontVariantForFontOptionLabel(fontOptionLabel),
  };
};
