import { Fragment, h } from 'preact';
import type { JSX } from 'preact';
import { RadioItem } from '../../../../../../../../shared/components/Menu/components/RadioItem.tsx';
import { FontOptionLabel } from '../../types.ts';
import { CAPTIONS_FONT_FAMILY_MAP } from '../../consts.ts';
import { getFontStyleForCaptions } from '../../utilities/getFontStyleForCaptions.ts';

type FontFamilyRadioGroupProps = {
  selectedFontOptionLabel: FontOptionLabel;
  setSelectedFontOptionLabel: (fontOptionLabel: FontOptionLabel) => void;
};

export const FontFamilyRadioGroup = ({
  selectedFontOptionLabel,
  setSelectedFontOptionLabel,
}: FontFamilyRadioGroupProps): JSX.Element => {
  return (
    <Fragment>
      {Array.from(CAPTIONS_FONT_FAMILY_MAP).map(([fontOptionLabel], index) => (
        <RadioItem
          key={fontOptionLabel}
          name="fontFamily"
          value={fontOptionLabel}
          checked={fontOptionLabel === selectedFontOptionLabel}
          onChange={() => setSelectedFontOptionLabel(fontOptionLabel)}
          shouldHaveRoundedBottomCorners={index === CAPTIONS_FONT_FAMILY_MAP.size - 1}
        >
          <span style={getFontStyleForCaptions(fontOptionLabel)}>{fontOptionLabel}</span>
        </RadioItem>
      ))}
    </Fragment>
  );
};
