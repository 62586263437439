import type { JSX } from 'preact';
import { h } from 'preact';
import { useState } from 'preact/hooks';
import { forwardRef, MouseEventHandler, PropsWithChildren } from 'preact/compat';
import { useMenuRootContext } from '../hooks/useMenuRootContext.tsx';
import {
  DEFAULT_DIALOG_MENU_ITEM_FONT_SIZE_PX,
  DEFAULT_BUTTON_MARGIN_RIGHT_PX,
} from '../../../../media/players/vulcanV2Player/shared/ui_components/UiConstants.ts';
import { CustomEventsWrapper } from '../../../CustomEventsWrapper.jsx';
import { isMouseDownRecently } from '../../../../../utilities/isMouseDown.js';

export type MenuItemButtonProps = PropsWithChildren<{
  onClick: MouseEventHandler<HTMLButtonElement>;
  shouldHaveRoundedBottomCorners?: boolean;
  shouldHaveRoundedTopCorners?: boolean;
}>;

export const MenuItemButton = forwardRef<HTMLButtonElement, MenuItemButtonProps>(
  (
    {
      children,
      onClick,
      shouldHaveRoundedBottomCorners = false,
      shouldHaveRoundedTopCorners = false,
    },
    forwardedRef,
  ): JSX.Element => {
    const {
      uiContext: { scale, controlBarBorderRadius },
    } = useMenuRootContext();
    const [isHovering, setIsHovering] = useState(false);
    const [isKeyboardFocused, setIsKeyboardFocused] = useState(false);

    const customEventsWrapperStyle = {
      alignItems: 'center',
      background: isHovering ? 'rgba(0,0,0,.3)' : '',
      borderBottomLeftRadius: shouldHaveRoundedBottomCorners ? `${controlBarBorderRadius}px` : '0',
      borderBottomRightRadius: shouldHaveRoundedBottomCorners ? `${controlBarBorderRadius}px` : '0',
      borderTopLeftRadius: shouldHaveRoundedTopCorners ? `${controlBarBorderRadius}px` : '0',
      borderTopRightRadius: shouldHaveRoundedTopCorners ? `${controlBarBorderRadius}px` : '0',
      boxShadow: isKeyboardFocused ? '0 0 0 2px #fff inset' : 'none',
      cursor: 'pointer',
      display: 'flex',
      fontSize: DEFAULT_DIALOG_MENU_ITEM_FONT_SIZE_PX * scale,
      marginRight: `${DEFAULT_BUTTON_MARGIN_RIGHT_PX * scale}px`,
      textAlign: 'left',
      width: '100%',
    };

    return (
      <CustomEventsWrapper
        elemRef={forwardedRef}
        class="w-css-reset-button-important w-vulcan-v2-button"
        tagName="button"
        onClick={onClick}
        onFocusIn={() => {
          if (!isMouseDownRecently()) {
            setIsKeyboardFocused(true);
          }
        }}
        onFocusOut={() => {
          setIsKeyboardFocused(false);
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={customEventsWrapperStyle}
      >
        {children}
      </CustomEventsWrapper>
    );
  },
);
