import { type ComponentChildren, h, type JSX } from 'preact';
import { useEffect, useMemo, useRef } from 'preact/hooks';
import { useMenuRootContext } from '../hooks/useMenuRootContext.tsx';
import { MenuItemButton } from './MenuItemButton.tsx';
import { MenuBackIcon } from './MenuBackIcon.tsx';
import { focusFirstFocusableElement } from '../../../../../utilities/focus.ts';

type MenuProps = {
  children: ComponentChildren;
  label?: string;
  menuKey?: string;
};

export const Menu = ({ children, label, menuKey = 'root' }: MenuProps): JSX.Element | null => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    currentMenuKey,
    goBack,
    uiContext: { scale },
    navigationDirection,
  } = useMenuRootContext();

  const isCurrentMenu = useMemo(() => {
    return menuKey === currentMenuKey;
  }, [currentMenuKey, menuKey]);

  const shouldRenderBackButton = useMemo(() => {
    return menuKey !== 'root';
  }, [menuKey]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    if (navigationDirection !== 'away-from-root') {
      return;
    }

    focusFirstFocusableElement(container);
  }, [isCurrentMenu, navigationDirection]);

  if (!isCurrentMenu) {
    return null;
  }

  return (
    <div class="w-css-reset w-css-reset-tree" ref={containerRef}>
      {shouldRenderBackButton && (
        <MenuItemButton onClick={goBack} shouldHaveRoundedTopCorners={true}>
          <MenuBackIcon scale={scale} />
          {label ?? 'Go back'}
        </MenuItemButton>
      )}
      {children}
    </div>
  );
};
