import type { JSX } from 'preact';
import { Fragment, h } from 'preact';
import { RadioItem } from '../../../../../../../../shared/components/Menu/components/RadioItem.tsx';
import { CharacterEdgeStyleLabel } from '../../types.ts';

const CHARACTER_EDGE_STYLES: CharacterEdgeStyleLabel[] = [
  'Depressed',
  'Drop shadow',
  'None',
  'Outline',
  'Raised',
];

type CharacterEdgeStyleRadioGroupProps = {
  selectedEdgeStyleOption: CharacterEdgeStyleLabel;
  setSelectedEdgeStyleOption: (selectedEdgeStyleOption: CharacterEdgeStyleLabel) => void;
};

export const CharacterEdgeStyleRadioGroup = ({
  selectedEdgeStyleOption,
  setSelectedEdgeStyleOption,
}: CharacterEdgeStyleRadioGroupProps): JSX.Element => {
  return (
    <Fragment>
      {CHARACTER_EDGE_STYLES.map((characterEdgeStyleLabel, index) => (
        <RadioItem
          key={characterEdgeStyleLabel}
          name="fontFamily"
          value={characterEdgeStyleLabel}
          checked={characterEdgeStyleLabel === selectedEdgeStyleOption}
          onChange={() => setSelectedEdgeStyleOption(characterEdgeStyleLabel)}
          shouldHaveRoundedBottomCorners={index === CHARACTER_EDGE_STYLES.length - 1}
        >
          <span>{characterEdgeStyleLabel}</span>
        </RadioItem>
      ))}
    </Fragment>
  );
};
