import { h } from 'preact';
import type { JSX } from 'preact';
import { useState } from 'preact/hooks';
import { visuallyHiddenElementStyles } from '../../../../media/players/vulcanV2Player/shared/ui_components/visuallyHiddenElementStyles.ts';
import { seqId } from '../../../../../utilities/seqid.js';
import { isMouseDownRecently } from '../../../../../utilities/isMouseDown.js';

const DIAMETER_PX = 24;

type RadioColorSwatchProps = Pick<JSX.DOMAttributes<HTMLInputElement>, 'onChange'> & {
  ariaLabel: string;
  checked: boolean;
  name: string;
  scale: number;
  value: string;
};

export const RadioColorSwatch = ({
  value,
  onChange,
  name,
  checked,
  ariaLabel,
  scale,
}: RadioColorSwatchProps): JSX.Element => {
  const [focused, setFocused] = useState(false);

  const radioId = seqId(`w-radio-${value}-`);

  const containerStyles = {
    flex: '0 0 auto',
  };

  const swatchStyles = {
    appearance: 'none',
    borderRadius: '50%',
    width: `${DIAMETER_PX * scale}px`,
    height: `${DIAMETER_PX * scale}px`,
    cursor: 'pointer',
    border: 'none',
    backgroundColor: value,
  };

  const focusRingStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    boxShadow: checked ? '0 0 0 2px #fff' : '',
    padding: '2px',
    transform: focused ? 'scale(1.2)' : '',
    transition: 'transform 150ms ease',
  };

  const onFocusInput = () => {
    if (!isMouseDownRecently()) {
      setFocused(true);
    }
  };

  const onBlurInput = () => {
    setFocused(false);
  };

  return (
    <div style={containerStyles}>
      <input
        type="radio"
        name={name}
        onChange={onChange}
        id={radioId}
        value={value}
        checked={checked}
        style={visuallyHiddenElementStyles}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
      />
      <div style={focusRingStyles}>
        <label htmlFor={radioId} style={swatchStyles} aria-label={ariaLabel} />
      </div>
    </div>
  );
};
