import { Component, h } from 'preact';
import { Color } from 'utilities/color.js';
import { getLoadedSelfHostedGoogleFonts, loadSelfHostedGoogleFont } from 'utilities/fonts.ts';

class Captions extends Component {
  constructor(props) {
    super(props);

    const loadedFonts = getLoadedSelfHostedGoogleFonts();
    if (!loadedFonts.includes(props.captionsFontFamily)) {
      loadSelfHostedGoogleFont(props.captionsFontFamily);
    }
  }

  componentDidUpdate(prevProps) {
    const loadedFonts = getLoadedSelfHostedGoogleFonts();
    if (
      this.props.captionsFontFamily != prevProps.captionsFontFamily &&
      !loadedFonts.includes(this.props.captionsFontFamily)
    ) {
      loadSelfHostedGoogleFont(this.props.captionsFontFamily);
    }
  }

  render() {
    const textLines = this.props.text.map((line, index) => this.renderLine(line, index));

    return (
      <div
        class="w-captions w-css-reset w-css-reset-tree w-vulcan-v2-button"
        style={this.rootContainerStyle()}
      >
        <div class="w-captions-window" style={this.groupStyle()}>
          {textLines}
        </div>
      </div>
    );
  }

  renderLine(line, index) {
    const opts = {
      isFirst: index === 0,
      isLast: index === this.props.text.length - 1,
    };

    const dir = this.props.rtl ? 'rtl' : 'ltr';

    return (
      <p class="w-captions-line" style={this.lineStyle()}>
        <div
          style={{ display: 'inline-block', transition: 'all 200ms ease', verticalAlign: 'bottom' }}
          class="w-css-reset"
        >
          <span
            dir={dir}
            style={this.spanStyle(opts)}
            dangerouslySetInnerHTML={{ __html: line }}
          ></span>
        </div>
      </p>
    );
  }

  rootContainerStyle() {
    const props = this.props;
    const gapSize = 18 * props.scale;
    const bottomOffset = props.controlsAreVisible ? 0 : -props.controlBarHeight;

    return {
      bottom: `${bottomOffset + gapSize}px`,
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      textAlign: 'center',
      width: '100%',
      transition: 'all 100ms ease',
    };
  }

  groupStyle() {
    const { captionsWindowColor, captionsWindowOpacityPercentage } = this.props;

    const backgroundColor = new Color(captionsWindowColor).alpha(captionsWindowOpacityPercentage);

    return {
      backgroundColor,
      display: 'inline-block',
      position: 'relative',
      margin: 'auto',
      maxWidth: '80%',
      outline: 'none',
      cursor: 'pointer',
    };
  }

  lineStyle() {
    return {
      lineHeight: '1em',
      margin: 0,
      padding: 0,
    };
  }

  spanStyle() {
    const {
      scale,
      captionsBackgroundOpacityPercentage,
      captionsBackgroundColor,
      captionsBorderRadius: borderRadius,
      captionsTextColor,
      captionsTextOpacityPercentage,
      captionsTextSize: textSize,
      captionsFontFamily: fontFamily,
      captionsFontVariant: fontVariant,
      captionsTextShadow: textShadow,
    } = this.props;

    const backgroundColor = new Color(captionsBackgroundColor).alpha(
      captionsBackgroundOpacityPercentage,
    );
    const textColor = new Color(captionsTextColor).alpha(captionsTextOpacityPercentage);

    return {
      background: backgroundColor,
      borderRadius: `${borderRadius}px`,
      color: textColor,
      display: 'block',
      fontFamily,
      fontSize: `${textSize * scale}px`,
      fontVariant,
      textShadow,
      lineHeight: '1em',
      overflow: 'hidden',
      padding: '.25em .6em',
      textOverflow: 'ellipsis',
      webkitFontSmoothing: 'antialiased',
      width: '100%',
      transition: 'all 200ms ease-in-out',
    };
  }
}

Captions.defaultProps = {
  captionsBorderRadius: 0,
  captionsBackgroundColor: '#000',
  captionsTextColor: '#fff',
  captionsTextSize: 18,
  captionsTextOpacityPercentage: 1,
  captionsFontVariant: 'normal',
  captionsTextShadow: 'none',
  captionsFontFamily: 'Inter',
  captionsBackgroundOpacityPercentage: 0.75,
  captionsWindowColor: '#000',
  captionsWindowOpacityPercentage: 0,
};

export default Captions;
