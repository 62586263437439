import { h } from 'preact';
import type { JSX } from 'preact';
import { useState } from 'preact/hooks';
import { PropsWithChildren } from 'preact/compat';
import { visuallyHiddenElementStyles } from '../../../../media/players/vulcanV2Player/shared/ui_components/visuallyHiddenElementStyles.ts';
import { isMouseDownRecently } from '../../../../../utilities/isMouseDown.js';
import { standardSvgAttrs } from '../../../../../utilities/svg_boilerplate.ts';
import { useMenuRootContext } from '../hooks/useMenuRootContext.tsx';
import {
  DEFAULT_DIALOG_MENU_ITEM_FONT_SIZE_PX,
  DEFAULT_BUTTON_HEIGHT_PX,
  DEFAULT_BUTTON_WIDTH_PX,
} from '../../../../media/players/vulcanV2Player/shared/ui_components/UiConstants.ts';

type CheckmarkProps = {
  visible: boolean;
};

const Checkmark = ({ visible }: CheckmarkProps): JSX.Element => {
  const {
    uiContext: { scale },
  } = useMenuRootContext();

  const svgStyleOverride = {
    height: `${DEFAULT_BUTTON_HEIGHT_PX * scale}px`,
    verticalAlign: 'middle',
    width: `${DEFAULT_BUTTON_WIDTH_PX * scale}px`,
    visibility: visible ? 'visible' : 'hidden',
  };

  return (
    <svg
      {...standardSvgAttrs({
        width: 40,
        height: 34,
        styleOverride: svgStyleOverride,
        ariaHidden: true,
      })}
      class="w-checkmark"
    >
      <polyline
        fill="none"
        stroke="#ffffff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        points="17,17 20,20 25,14 "
      />
    </svg>
  );
};

type RadioItemProps = PropsWithChildren<{
  checked: boolean;
  name: string;
  onChange: JSX.GenericEventHandler<HTMLInputElement>;
  shouldHaveRoundedBottomCorners?: boolean;
  value: string;
}>;

export const RadioItem = ({
  value,
  onChange,
  name,
  checked,
  children,
  shouldHaveRoundedBottomCorners = false,
}: RadioItemProps): JSX.Element => {
  const {
    uiContext: { scale, controlBarBorderRadius },
  } = useMenuRootContext();
  const [isFocused, setIsFocused] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);

  const onFocus = () => {
    if (isMouseDownRecently()) {
      return;
    }
    setIsFocused(true);
  };

  const onBlur = () => setIsFocused(false);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      style={{
        cursor: 'pointer',
        outline: 'none',
        backgroundColor: isHovering ? 'rgba(0,0,0,.3)' : '',
        boxShadow: isFocused ? 'inset 0 0 0 2px #fff' : '',
        fontSize: DEFAULT_DIALOG_MENU_ITEM_FONT_SIZE_PX * scale,
        borderBottomLeftRadius: shouldHaveRoundedBottomCorners
          ? `${controlBarBorderRadius}px`
          : '0',
        borderBottomRightRadius: shouldHaveRoundedBottomCorners
          ? `${controlBarBorderRadius}px`
          : '0',
      }}
    >
      <input
        type="radio"
        name={name}
        onChange={onChange}
        id={value}
        style={visuallyHiddenElementStyles}
        value={value}
        checked={checked}
      />
      <label htmlFor={value}>
        <Checkmark visible={checked} />
        {children}
      </label>
    </div>
  );
};
