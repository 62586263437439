import type { ComponentChildren, JSX } from 'preact';
import { createContext, h } from 'preact';
import { useContext, useMemo, useReducer } from 'preact/hooks';
import { BaseControlProps } from '../../../../../types/controls.ts';

type UiContextValue = {
  controlBarBorderRadius: BaseControlProps['controlBarBorderRadius'];
  scale: BaseControlProps['scale'];
};

type NavigationDirection = 'away-from-root' | 'none' | 'towards-root';

type MenuRootContextValue = {
  currentMenuKey: string;
  goBack: () => void;
  goToRoot: () => void;
  navigationDirection: NavigationDirection;
  openMenu: (menuKey: string) => void;
  prevMenuKey: string | null;
  uiContext: UiContextValue;
};

const MenuContext = createContext<MenuRootContextValue | null>(null);

type MenuState = {
  menuStack: string[];
  navigationDirection: NavigationDirection;
  prevMenuKey: string | null;
};

type MenuStateReducerAction =
  | {
      menuKey: string;
      type: 'OPEN_SUBMENU';
    }
  | {
      type: 'GO_BACK';
    }
  | {
      type: 'GO_TO_ROOT';
    }
  | {
      type: 'NAVIGATION_END';
    };

const menuStateReducer = (prevState: MenuState, action: MenuStateReducerAction) => {
  const prevMenuKey = prevState.menuStack[prevState.menuStack.length - 1];

  switch (action.type) {
    case 'OPEN_SUBMENU':
      return {
        menuStack: [...prevState.menuStack, action.menuKey],
        prevMenuKey,
        navigationDirection: 'away-from-root' as const,
      };
    case 'GO_BACK':
      return {
        menuStack: prevState.menuStack.slice(0, -1),
        prevMenuKey,
        navigationDirection: 'towards-root' as const,
      };
    case 'GO_TO_ROOT':
      return {
        menuStack: ['root'],
        prevMenuKey,
        navigationDirection: 'towards-root' as const,
      };
    case 'NAVIGATION_END':
      return {
        ...prevState,
        navigationDirection: 'none' as const,
      };
    default:
      return prevState;
  }
};

type MenuRootProps = {
  children: ComponentChildren;
  controlBarBorderRadius: UiContextValue['controlBarBorderRadius'];
  scale: UiContextValue['scale'];
};

export const MenuRoot = ({
  children,
  controlBarBorderRadius,
  scale,
}: MenuRootProps): JSX.Element => {
  const initialState: MenuState = {
    menuStack: ['root'],
    prevMenuKey: null,
    navigationDirection: 'none',
  };

  const [state, dispatch] = useReducer(menuStateReducer, initialState);

  const { navigationDirection, prevMenuKey, menuStack } = state;

  const currentMenuKey = useMemo(() => menuStack[menuStack.length - 1] ?? 'root', [menuStack]);

  const contextValue = useMemo(() => {
    const goBack = () => dispatch({ type: 'GO_BACK' });
    const openMenu = (menuKey: string) => dispatch({ menuKey, type: 'OPEN_SUBMENU' });
    const goToRoot = () => dispatch({ type: 'GO_TO_ROOT' });

    return {
      goBack,
      goToRoot,
      openMenu,
      currentMenuKey,
      uiContext: { scale, controlBarBorderRadius },
      navigationDirection,
      prevMenuKey,
    };
  }, [currentMenuKey, scale, controlBarBorderRadius, navigationDirection, prevMenuKey]);

  return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
};

export const useMenuRootContext = (): MenuRootContextValue => {
  const context = useContext(MenuContext);

  if (context === null) {
    throw new Error('useMenuRootContext must be used within a MenuRoot');
  }

  return context;
};
