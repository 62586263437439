import { h } from 'preact';
import type { JSX } from 'preact';
import { PropsWithChildren } from 'preact/compat';

type RadioGroupProps = PropsWithChildren<{ ariaLabel: string; scale: number }>;

const GAP_PX = 8;

export const RadioGroup = ({ children, ariaLabel, scale }: RadioGroupProps): JSX.Element => {
  const containerStyle = {
    display: 'flex',
    gap: `${GAP_PX * scale}px`,
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'start',
    width: 'fit-content',
  };

  return (
    <div role="radiogroup" aria-label={ariaLabel} style={containerStyle}>
      {children}
    </div>
  );
};
