import { h, type JSX } from 'preact';
import type { CSSProperties, PropsWithChildren } from 'preact/compat';
import { DEFAULT_DIALOG_MENU_ITEM_FONT_SIZE_PX } from '../../../../shared/ui_components/UiConstants.ts';
import { useMenuRootContext } from '../../../../../../../shared/components/Menu/hooks/useMenuRootContext.tsx';
import { SETTINGS_MENU_PADDING_X_PX } from '../consts.ts';

const LEGEND_LINE_HEIGHT_PX = 18;
const PADDING_Y_PX = 8;
const GAP_PX = 8;

const Label = ({ title }: { title: string }): JSX.Element => {
  const {
    uiContext: { scale },
  } = useMenuRootContext();

  const labelStyle = {
    fontSize: DEFAULT_DIALOG_MENU_ITEM_FONT_SIZE_PX * scale,
    lineHeight: `${LEGEND_LINE_HEIGHT_PX * scale}px`,
    padding: `0 ${SETTINGS_MENU_PADDING_X_PX * scale}px`,
  };

  return <label style={labelStyle}>{title}</label>;
};

export const CaptionsSettingsMenuSection = ({
  children,
  title,
  style = {},
}: PropsWithChildren<{ style?: CSSProperties; title: string }>): JSX.Element => {
  const {
    uiContext: { scale },
  } = useMenuRootContext();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: `${GAP_PX * scale}px`,
    padding: `${PADDING_Y_PX * scale}px 0`,
    ...style,
  };

  return (
    <div style={containerStyle}>
      <Label title={title} />
      {children}
    </div>
  );
};
