import { h, type JSX } from 'preact';
import { standardSvgAttrs } from '../../../../../utilities/svg_boilerplate.ts';
import {
  BASE_CONTROL_BAR_HEIGHT_PX,
  DEFAULT_BUTTON_WIDTH_PX,
} from '../../../../media/players/vulcanV2Player/shared/ui_components/UiConstants.ts';

export const MenuBackIcon = ({ scale }: { scale: number }): JSX.Element => {
  const svgAttrs = standardSvgAttrs({
    width: 40,
    height: 34,
    styleOverride: {
      height: `${BASE_CONTROL_BAR_HEIGHT_PX * scale}px`,
      verticalAlign: 'middle',
      visibility: 'visible',
      width: `${DEFAULT_BUTTON_WIDTH_PX * scale}px`,
    },
    ariaHidden: true,
  }) as JSX.SVGAttributes<SVGSVGElement>;

  return (
    <svg {...svgAttrs}>
      <path
        d="M21.6889 22.0889C21.5438 22.0889 21.3988 22.0337 21.2884 21.9227L16.5662 17.2004C16.3448 16.9791 16.3448 16.6202 16.5662 16.3988L21.2884 11.6773C21.5098 11.456 21.8687 11.456 22.0901 11.6773C22.3115 11.8987 22.3115 12.2576 22.0901 12.479L17.7683 16.8008L22.0901 21.1225C22.3115 21.3439 22.3115 21.7028 22.0901 21.9242C21.9798 22.0345 21.8347 22.0904 21.6896 22.0904L21.6889 22.0889Z"
        fill="white"
      />
    </svg>
  );
};
