import { h, type JSX } from 'preact';
import { useState } from 'preact/hooks';
import { visuallyHiddenElementStyles } from '../../../../media/players/vulcanV2Player/shared/ui_components/visuallyHiddenElementStyles.ts';
import { seqId } from '../../../../../utilities/seqid.js';
import { isMouseDownRecently } from '../../../../../utilities/isMouseDown.js';

const PADDING_X_PX = 10;
const PADDING_Y_PX = 2;
const FONT_SIZE_PX = 12;
const LINE_HEIGHT_PX = 16;

type RadioPillProps = Pick<JSX.DOMAttributes<HTMLInputElement>, 'onChange'> & {
  checked: boolean;
  label: string;
  name: string;
  scale: number;
  value: string;
};

export const RadioPill = ({
  value,
  label,
  onChange,
  name,
  checked,
  scale,
}: RadioPillProps): JSX.Element => {
  const [focused, setFocused] = useState(false);

  const onFocusInput = () => {
    if (!isMouseDownRecently()) {
      setFocused(true);
    }
  };

  const onBlurInput = () => {
    setFocused(false);
  };

  const pillStyles = {
    appearance: 'none',
    borderRadius: 99999,
    padding: `${PADDING_Y_PX * scale}px ${PADDING_X_PX * scale}px`,
    fontSize: `${FONT_SIZE_PX * scale}px`,
    cursor: 'pointer',
    border: '1px solid #82828A',
    lineHeight: `${LINE_HEIGHT_PX * scale}px`,
    backgroundColor: checked ? 'white' : 'transparent',
    color: checked ? '#242528' : '#fff',
    outline: 'none',
    boxShadow: focused ? '0 0 0 2px #fff' : 'none',
    flex: '0 0 auto',
  };

  const radioId = seqId(`w-radio-${value}-`);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="radio"
        name={name}
        onChange={onChange}
        id={radioId}
        style={visuallyHiddenElementStyles}
        value={value}
        checked={checked}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
      />
      <label htmlFor={radioId} style={pillStyles}>
        {label}
      </label>
    </div>
  );
};
